/**
 * This file inits the scroll header
 *
 * @package jfo
 * @since 1.0.0
 * @author hype.media <web@hype-media.de>
 */

export function scrollHeader() {
    const body = document.body;

    // Variables
    let start = 50;

    // Listen to scroll events
    window.addEventListener( 'scroll', () => {
        // Get the current scroll value
        const currentScroll = window.pageYOffset;

        // Check if current scroll value is
        // bigger than threshold or not
        if ( currentScroll >= start ) {
            // Update body class
            body.classList.add( 'switch-header' );
        } else {
            // Update body class
            body.classList.remove( 'switch-header' );
        }
    } );
}
