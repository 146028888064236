/**
 * Offcanva menu helper
 *
 * @package jfo
 * @since 1.0.0
 * @author hype.media <web@hype-media.de>
 */
import { Offcanvas } from "bootstrap";

function scrollTo( element, to, duration ) {
    if ( duration <= 0 ) return;
    let difference = to - element.scrollTop;
    let perTick = difference / duration * 10;

    setTimeout( function () {
        element.scrollTop = element.scrollTop + perTick;
        if ( element.scrollTop === to ) return;
        scrollTo( element, to, duration - 10 );
    }, 10 );
}


export function offcanvasMenu() {
    // Get the menu element
    let offMenu = document.getElementById( 'overlay-menu' );

    // Get all links in the Offcanvas menu
    let els = offMenu.getElementsByTagName( 'a' );

    let handleLinkClick = function ( e ) {
        e.stopPropagation();

        // Get the Bootstrap Offcanvas instance
        let bsOffMenuMenu = Offcanvas.getInstance( offMenu );

        // Hide the Offcanvs menu with the provided method
        // See: https://getbootstrap.com/docs/5.1/components/offcanvas/#methods
        bsOffMenuMenu.hide();

        // Get the link target from the clicked link, remove the hash
        let scrollTarget = e.target.hash.replace( /#/, "" );

        // Get the section to scroll to
        let scrollElement = document.getElementById( scrollTarget );

        // Scroll to element
        scrollElement.scrollIntoView();
    }

    // Create an array an loop through all links
    Array.from( els ).forEach( function ( el ) {
        // Listen for click events
        el.addEventListener( 'click', handleLinkClick );
    } );
}
