/**
 * ==== Bootstrap Plugins ====
 * Bootstrap plugins are auto-invoked
 */
import "bootstrap/js/dist/collapse";

/**
 * ==== Helpers on page load ====
 * - pageInit: Run some general stuff
 * - resetPage: Move footer to the bottom of the page
 */
import { resetPage, pageInit } from "./reset";
import { scrollHeader } from "./scroll"
import { offcanvasMenu } from "./menu";

pageInit();
resetPage();
scrollHeader();
offcanvasMenu();

/**
 * Run functions on window-resize
 * - resetPage: Move footer to the bottom of the page
 */
window.addEventListener( "resize", function () {
    resetPage();
} );
